module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-5F0YD0HDY4","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/silvianaho.github.io/silvianaho.github.io/node_modules/gatsby-remark-embed-video","id":"b1499700-2733-5c51-81c2-4497aaa628b0","name":"gatsby-remark-embed-video","version":"3.1.1","modulePath":"/home/runner/work/silvianaho.github.io/silvianaho.github.io/node_modules/gatsby-remark-embed-video/index.js","pluginOptions":{"plugins":[],"width":800,"ratio":1.77,"height":400,"related":false,"noIframeBorder":true,"loadingStrategy":"lazy","urlOverrides":[{"id":"youtube"}],"containerClass":"embedVideo-container","iframeId":false},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/silvianaho.github.io/silvianaho.github.io/node_modules/gatsby-remark-external-links","id":"5bee27b9-6073-5559-9b0f-cfd309340985","name":"gatsby-remark-external-links","version":"0.0.4","modulePath":"/home/runner/work/silvianaho.github.io/silvianaho.github.io/node_modules/gatsby-remark-external-links/index.js","pluginOptions":{"plugins":[],"target":"_blank","rel":"nofollow noopener noreferrer"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/silvianaho.github.io/silvianaho.github.io/node_modules/gatsby-remark-highlight-code","id":"65396f67-870a-51b7-b4b9-60d356da63f6","name":"gatsby-remark-highlight-code","version":"3.2.0","modulePath":"/home/runner/work/silvianaho.github.io/silvianaho.github.io/node_modules/gatsby-remark-highlight-code/index.js","pluginOptions":{"plugins":[],"lineNumbers":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/silvianaho.github.io/silvianaho.github.io","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"quality":100,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]

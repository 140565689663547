import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

// tailwindcss navbar with logo
const Navbar = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { title } = data.site.siteMetadata

  const [isOpen, setIsOpen] = React.useState(false)

  const Links = [
    {
      name: "About",
      link: "/#about"
    },
    {
      name: "Experience",
      link: "/#experience"
    },
    {
      name: "Blog",
      link: "/blog"
    },
    {
      name: "Contact",
      link: "/#contact"
    }
  ]

  return (
    <header>
      <nav className="fixed top-0 left-0 w-full bg-white/90 drop-shadow-md px-4 sm:px-6 md:px-16 py-4 rounded dark:bg-neutral-900/90 z-20">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <a href="/" className="flex items-center">
            <span className="self-center text-2xl font-semibold whitespace-nowrap">
              { title }
            </span>
          </a>
          <div className="hidden md:block md:w-auto">
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              {Links.map(({ name, link }) => (
                <li key={name} className="flex items-center">
                  <a
                    href={link}
                    className="block px-4 py-2 text-gray-700 dark:text-gray-400 hover:text-stone-900 dark:hover:text-white"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* hamburger menu 🍔 */}
          <button
            data-collapse-toggle="mobile-menu"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:focus:ring-gray-600"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Open menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsOpen(!isOpen)}
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>

          {/* mobile menu */}
          {isOpen && (
            <div className="container h-screen md:hidden" id="mobile-menu">
              <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                {Links.map(({ name, link }) => (
                  <li key={name} className="flex items-center">
                    <a
                      href={link}
                      className="block px-4 py-2 text-gray-700 dark:text-gray-400 hover:text-stone-900 dark:hover:text-white"
                    >
                      {name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </header>
  )
}

export default Navbar;

// overall layout
import * as React from "react"
// import { Helmet } from "react-helmet"
import Navbar from "./Navbar"

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="flex flex-col bg-white dark:bg-neutral-900 text-neutral-900 dark:text-white">
      <Navbar />
      <main className="px-6 py-4 md:px-16 xl:px-64 min-h-screen w-full">{children}</main>
    </div>
  )
}

export default Layout
